import React from 'react';
import Layout from '../containers/layout';

const AboutMePage: React.FC = () => {
  return (
    <Layout>
      <div className="flex flex-col items-center">
        <h1>ABOUT-ME</h1>
        <p>More to come</p>
      </div>
    </Layout>
  );
};

export default AboutMePage;
